import React from "react";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import {WalletConnectButton } from '@sei-js/react';


const Navbar = () => {
  return (
    <nav>
      <div className="nav-container">
        <div className="nav-images">
          <img src="./images/logo.png" alt="" />
          <div className="chatbox">
            <img src="./images/scrollBubble.png" alt="" />
          </div>
        </div>
        <div className="links">
          <a href="https://twitter.com/SeiPengos" className="link">
            <FontAwesomeIcon className="icon" icon={faSquareXTwitter} />
          </a>
          <a href="https://discord.gg/BDJ6xpfNRw" className="link">
            <FontAwesomeIcon className="icon" icon={faDiscord} />
          </a>
          <WalletConnectButton buttonClassName='connect-wallett' />
          {/* <a className="connect-wallet" ></a> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
