import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { SeiWalletProvider } from "@sei-js/react";
const root = ReactDOM.createRoot(document.getElementById("root"));
const chainId = 'atlantic-2'
const restUrl = 'https://rest.atlantic-2.seinetwork.io/'
const rpcUrl = 'https://rpc.atlantic-2.seinetwork.io'
root.render(
  <React.StrictMode>
    <SeiWalletProvider chainConfiguration={{ chainId, restUrl, rpcUrl }} wallets={['compass']} autoConnect={'compass'}   >
    <App />
    </SeiWalletProvider>
  </React.StrictMode>
);
