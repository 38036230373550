import React from "react";
import "./Canvas.css";

const Canvas = () => {
  return (
    <section id="canvas">
      <h1 className="main-heading" id="mainheading">
        Sei Pengos
      </h1>
      <img className="mountains" src="./images/penguins.svg" alt="" />
      <div className="sun"></div>
    </section>
  );
};

export default Canvas;
