import { React, useState } from "react";
import "./Task.css";

export const Task = ({
  taskIndex,
  taskParaSuffix,
  style,
  setStyle,
  taskPara,
  taskAnchor,
  taskLink,
  inputPlaceholder,
  taskFunc,
  color,
  popout,
  setPopout,
  setdata,
  data,
}) => {
  const onChange = (e) => {
    setdata(e.target.value);
  };
  return (
    <section id="task-container" style={style}>
      <div
        className="black"
        onClick={() => setStyle({ display: "none" })}
      ></div>
      <div className="task" style={{ background: color }}>
        <img
          src={`./images/image_${taskIndex}-nobg.png`}
          className="img-cutie"
          alt=""
        />
        <h3>
          {taskPara}{" "}
          <a target="_black" href={taskLink}>
            {taskAnchor}
          </a>
          {" "}{taskParaSuffix}
        </h3>
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
            taskFunc(data);
          }}
        >
          <input
            type="text"
            placeholder={inputPlaceholder}
            onChange={onChange}
            required
          />
          <input type="submit" value="Submit" />
        </form>
      </div>
    </section>
  );
};
