import React from "react";
import "./Nftshowcase.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const Nftshowcase = ({ showTask, taskStyles }) => {
  // const [task1, setTask1] = useState({display: 'none'})
  // const [task2, setTask2] = useState({display: 'none'})
  // const [task3, setTask3] = useState({display: 'none'})
  // const [task4, setTask4] = useState({display: 'none'})

  return (
    <section id="nft-showcase">
      <div className="showcases">
        <div className="showcases-a">
          <div>
            <img
              src="./images/image_1.png"
              alt=""
              className="showcase"
              onClick={() => showTask(0)}
            />
            <FontAwesomeIcon className={ taskStyles[0]?'hidden tick':'tick'} icon={faCircleCheck} />
          </div>
          <div>
            <img
              src="./images/image_2.png"
              alt=""
              className="showcase"
              onClick={() => showTask(1)}
            />
            <FontAwesomeIcon className={ taskStyles[1]?'hidden tick':'tick'} icon={faCircleCheck} />
          </div>
        </div>
        <div className="showcases-b">
          <div>
            <img
              src="./images/image_3.png"
              alt=""
              className="showcase"
              onClick={() => showTask(2)}
            />
            <FontAwesomeIcon className={ taskStyles[2]?'hidden tick':'tick'} icon={faCircleCheck} />
          </div>
          <div>
            <img
              src="./images/image_4.png"
              alt=""
              className="showcase"
              onClick={() => showTask(3)}
            />
            <FontAwesomeIcon className={ taskStyles[3]?'hidden tick':'tick'} icon={faCircleCheck} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Nftshowcase;
