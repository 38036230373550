import React from "react";
import "./Info.css";

const Info = () => {
  return (
    <section id="info">
      <div className="info">
        <div className="paras">
          <h3>Sei Pengos</h3>
          <p>
          Sei Pengos is an Early Collection on Sei focused on Gaming, Staking, Community, Tools for Developers and Analysts and much more. The Launch of the Game begins with the Minting on OG Pengos in Community.

          </p>
        </div>
        <img src="./images/image_3.png" className="info-img" alt="" />
      </div>
    </section>
  );
};

export default Info;
