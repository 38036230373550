import "./App.css";
import Canvas from "./components/Canvas";
import Navbar from "./components/Navbar";
import Nftshowcase from "./components/Nftshowcase";
import Info from "./components/Info";
import { Task } from "./components/Task";
import { useState } from "react";
import io from "socket.io-client";
import { useWallet } from "@sei-js/react";
const socket = io.connect("https://c99t9bqvi7.execute-api.ap-south-1.amazonaws.com:3001/");
// const socket = io.connect('http://localhost:3001')

function App() {
  // const [creds, setCreds] = useState({ twitter_id: "", discord_id: "", quote_link: "", zily_username: "" });
  const [data, setdata] = useState();
  const [twitter, settwitter] = useState(true);
  const [discord, setdiscord] = useState(true);
  const [retweet, setretweet] = useState(true);
  const [zealy, setzealy] = useState(true);
  const [alertt, setAlert] = useState({});

  const statesoftask = [twitter, discord, retweet, zealy];

  const { connectedWallet, accounts } = useWallet();

  // once
  if (connectedWallet) {
    // console.log(accounts[0].address)
    socket.emit("address", accounts[0].address);

    socket.once("data", (data) => {
      // console.log(data);

      settwitter(data.twitter === "");
      setdiscord(data.discord === "");
      setretweet(data.quote_link === "");
      setzealy(data.zily_username === "");
    });
  }

  const taskStyles = [
    [
      ...useState({ display: "none" }),
      "Follow Sei Pengos on ",
      "",
      "Twitter",
      "https://twitter.com/intent/follow?screen_name=SeiPengos",
      "Twitter Username",
      (data) => {
        socket.emit(
          "twitter",
          JSON.stringify({
            twitter_id: data,
            address: accounts[0].address,
            task: "twitter",
          })
        );
        settwitter(false);
        for (let j = 0; j <= taskStyles.length - 1; j++)
          taskStyles[j][1]({ display: "none" });
      },
      "#89d194",
    ],
    [
      ...useState({ display: "none" }),
      "Join our ",
      "",
      "Discord server",
      "https://discord.gg/BDJ6xpfNRw",
      "Discord Username",
      (data) => {
        socket.emit(
          "discord",
          JSON.stringify({
            discord_id: data,
            address: accounts[0].address,
            task: "discord",
          })
        );
        setdiscord(false);
        for (let j = 0; j <= taskStyles.length - 1; j++)
          taskStyles[j][1]({ display: "none" });
      },
      "#d1c389",
    ],
    [
      ...useState({ display: "none" }),
      "Retweet our ",
      "",
      "Tweet",
      "https://twitter.com/intent/follow?screen_name=SeiPengos",
      "Retweet link",
      (data) => {
        socket.emit(
          "quote_link",
          JSON.stringify({
            quote_link: data,
            address: accounts[0].address,
            task: "quote_link",
          })
        );
        setretweet(false);
        for (let j = 0; j <= taskStyles.length - 1; j++)
          taskStyles[j][1]({ display: "none" });
      },
      "#89d1cd",
    ],
    [
      ...useState({ display: "none" }),
      "Compose a tweet about us on",
      "",
      "Twitter",
      "https://twitter.com/intent/tweet?text=I%20just%20signed%20up%20for%20%40SeiPengos%20%F0%9F%90%A7%20Whitelist%20Campaign%20on%20their%20website%F0%9F%8F%86%0D%0A%0D%0AFirst%20come%20first%20serve%20join%20yourself%20now%20seipengos.club%20%0D%0A%0D%0A%23SeiNetwork%20%23SeiNFTs%20%23Whitelist",
      "Tweet Link",
      (data) => {
        socket.emit(
          "zily_username",
          JSON.stringify({
            zily_username: data,
            address: accounts[0].address,
            task: "zily_username",
          })
        );

        setzealy(false);
        for (let j = 0; j <= taskStyles.length - 1; j++)
          taskStyles[j][1]({ display: "none" });
      },
      "#bf4949",
    ],
  ];

  // function taskconfirmer(i){
  //   taskStyles[0][10](false)
  // }

  function showTask(i) {
    if (connectedWallet) {
      if (statesoftask[i]) {
        if (
          taskStyles[0][0].display !== "none" ||
          taskStyles[1][0].display !== "none" ||
          taskStyles[2][0].display !== "none" ||
          taskStyles[3][0].display !== "none"
        ) {
          for (let j = 0; j <= taskStyles.length - 1; j++)
            taskStyles[j][1]({ display: "none" });
        }
        taskStyles[i][1]({});
      }
    } else {
      setAlert({ display: "block" });
      setTimeout(() => {
        setAlert({});
      }, 3000);
    }
  }

  return (
    <div className="App">
      <div className="alertwin" style={alertt}>
        Connect your wallet
      </div>
      <Navbar></Navbar>
      {taskStyles.map((task, i) => {
        // console.log(task);
        return (
          <Task
            data={data}
            setdata={setdata}
            taskIndex={i + 1}
            style={task[0]}
            setStyle={task[1]}
            taskPara={task[2]}
            taskParaSuffix={task[3]}
            taskAnchor={task[4]}
            taskLink={task[5]}
            inputPlaceholder={task[6]}
            taskFunc={task[7]}
            color={task[8]}
            popout={statesoftask[i]}
            // setPopout={task[10]}
          />
        );
      })}
      <div
        id="main-body"
        onScroll={() => {
          function scrollValue(a) {
            document.getElementById("mainheading").style.top =
              1200 <
              (Number(window.innerHeight) * a) / 10 +
                Number(document.getElementById("main-body").scrollTop) * 1.5
                ? "500px"
                : (Number(window.innerHeight) * a) / 10 +
                  Number(document.getElementById("main-body").scrollTop) * 1.5 +
                  "px";
            document.querySelector(".sun").style.top =
              1200 <
              (Number(window.innerHeight) * a) / 10 +
                Number(document.getElementById("main-body").scrollTop) * 1.5
                ? "500px"
                : 10 +
                  (Number(document.getElementById("main-body").scrollTop) *
                    0.02) **
                    2 +
                  "vh";
            document.querySelector(".sun").style.right =
              20 -
              Number(document.getElementById("main-body").scrollTop) * 0.07 +
              "vw";
          }
          window.outerWidth > 600 ? scrollValue(3.5) : scrollValue(4);

          if (
            Number(document.getElementById("main-body").scrollTop) >
              Number(window.innerHeight - 100) &&
            Number(document.getElementById("main-body").scrollTop) <
              Number(2 * window.innerHeight - 100)
          ) {
            document.querySelector(".showcases-a").style.opacity = "1";
            document.querySelector(".showcases-b").style.opacity = "1";
            document.querySelector(".showcases-a").style.translate = "0";
            document.querySelector(".showcases-b").style.translate = "0";
            setTimeout(() => {
              document.querySelector(".showcases").style.scale = "1.1";
            }, 500);
          } else if (
            Number(document.getElementById("main-body").scrollTop) >
            Number(2 * window.innerHeight - 100)
          ) {
            document.querySelector(".paras").style.translate = "0";
            document.querySelector(".info-img").style.translate = "0";
            document.querySelector(".info").style.opacity = "1";
          }
        }}
      >
        <Canvas></Canvas>
        <Nftshowcase
          taskStyles={statesoftask}
          showTask={showTask}
        ></Nftshowcase>
        <Info></Info>
      </div>
    </div>
  );
}

export default App;
